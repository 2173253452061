import { Application } from "./model/Application";


export const applications : Application[] = [
    {
        name: "People Operations", 
        desc: "",
        launchUrl: ""
    },
    {
        name: "Projects Report",
        desc: "",
        launchUrl: ""
    },
    {
        name: "Sharepoint Analiyzer",
        desc: "",
        launchUrl: ""
    },
    {
        name: "Ask Archie",
         desc: "",
        launchUrl: "https://archie.hyperaptive.io/"
    },
    {
        name: "Resume Cards", 
        desc: "",
        launchUrl: ""
    },
    {
        name: "Deal Tracker",
        desc: "",
        launchUrl: "https://dash.hyperaptive.io/dealperformance/"
    },
    {
        name: "Account Performance",
        desc: "",
        launchUrl: "https://dash.hyperaptive.io/accountperformance/"
    },
    {
        name: "Aptive Across America",
        desc: "",
        launchUrl: "https://acrossamerica.hyperaptive.io/"
    },
    {
        name: "Vendor Payments",
        desc: "",
        launchUrl: "https://www.tools.hyperaptive.io/vendor_payments"
    }
]