import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Home from './Home';
import logo from "./assets/aptive_logo.png";
import './App.css';
import './index.css';

interface Token {
  access_token: string;
  [key: string]: any; // If the token contains additional properties
}

const App: React.FC = () => {
  const [token, setToken] = useState('');
  const [user, setUser] = useState();
  const serverUrl = process.env.REACT_APP_URL;

  useEffect(() => {
    const cookieToken = Cookies.get('access_token');
    if (cookieToken) {
      let accessToken: string = JSON.parse(cookieToken);
      const tokenExpiry = Cookies.get('expires_at');
      const current = new Date().toISOString();
      if(current < JSON.stringify(tokenExpiry)){
        const refresh_token = Cookies.get('refresh_token');
        fetch(`${serverUrl}/refresh?refresh_token=${refresh_token}`).then(resp => {
          resp.json().then(token => accessToken = token.token);
        })
      }
      setToken(accessToken);
      localStorage.setItem('access-token', accessToken);
    }
  }, []); // `token` is not needed as a dependency since you want to set it once on mount

  const handleLogin = () => {
    window.location.href = `${serverUrl}/auth/login`;
  };

  const handleLogout = () => {
    window.location.href = `${serverUrl}/auth/logout`;
  };

  
    return (
    <>
      {token ? <Home /> :  <div className='App'><img src={logo} className="App-logo" alt="logo" /><div className='App-header'>
      <h1>Welcome to aptive internal tools home</h1>
       <div>
        <button className="button" onClick={handleLogin}>Login</button>
      </div>
    </div></div> }
    </>
    );
  }

export default App;
