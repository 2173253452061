'use client'

import { useEffect, useState } from "react"
import { Bell, ChevronDown, ExternalLink, Layout, LogOut, Plus, Search, Settings, Users } from "lucide-react"
import { Button } from "./components/ui/button"
import { Input } from "./components/ui/input"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./components/ui/dropdown-menu"
import { applications } from "./apps"
import Cookies from 'js-cookie';
import logo from "./assets/aptive_logo.png";
import { Avatar, AvatarFallback, AvatarImage } from "./components/ui/avatar"


interface Token {
  access_token: string;
  [key: string]: any; // If the token contains additional properties
}

const Home: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("")

  const filteredApplications = applications.filter((app) =>
    app.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-y-auto p-6">
          <SearchAndAddSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <ApplicationGrid applications={filteredApplications} />
        </main>
      </div>
    </div>
  )
}

function Sidebar() {
  return (
    <div className="w-64 bg-[#1b365d] text-white p-6">
      <h1 className="text-2xl font-bold mb-8">Welcome</h1>
      <nav>
        <ul className="space-y-4">
          <SidebarItem icon={<Layout className="h-5 w-5" />} label="Dashboard" />
          <SidebarItem icon={<Settings className="h-5 w-5" />} label="Settings" />
        </ul>
      </nav>
    </div>
  )
}

function SidebarItem({ icon, label }: { icon: React.ReactNode; label: string }) {
  return (
    <li>
      <a href="#" className="flex items-center space-x-2 hover:text-gray-300">
        {icon}
        <span>{label}</span>
      </a>
    </li>
  )
}

function Header() {
  return (
    <header className="bg-white shadow-sm">
      <div className="flex items-center justify-between px-6 py-4">
        <h2 className="text-2xl font-semibold text-gray-800">Your Applications</h2>
        <img src={logo} className="App-logo" alt="logo"/>
        <div className="flex items-center space-x-4">
          <UserMenu />
        </div>
      </div>
    </header>
  )
}

function UserMenu() {
  const [user, setUser] = useState();

  useEffect(() => {
    let accessToken = localStorage.getItem('access-token');
      var options = { headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${accessToken}`
        }
      };
      fetch('https://graph.microsoft.com/oidc/userinfo', options).then(response => {
        response.json().then(user => setUser(user));
      })
  }, []);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="flex items-center space-x-2">
        <Avatar>
            <AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" />
            <AvatarFallback>JD</AvatarFallback>
          </Avatar>
          <span>{user?.given_name}</span>
          <ChevronDown className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem>Profile</DropdownMenuItem>
        <DropdownMenuItem>Settings</DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <LogOut className="mr-2 h-4 w-4" />
          <span>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

function SearchAndAddSection({ searchTerm, setSearchTerm }: { searchTerm: string; setSearchTerm: (term: string) => void }) {
  return (
    <div className="mb-6 flex items-center justify-between">
      <div className="relative w-64">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
        <Input
          type="search"
          placeholder="Search applications"
          className="pl-8"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    </div>
  )
}

function ApplicationGrid({ applications }: { applications: typeof applications }) {
  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
      {applications.map((app) => (
        <ApplicationCard key={app.id} app={app} />
      ))}
    </div>
  )
}

function ApplicationCard({ app }: { app: typeof applications[0] }) {
  return (
    <div className="bg-white shadow-sm rounded-lg overflow-hidden">
      <div className="p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">{app.name}</h3>
        <LaunchButton url={app.launchUrl} />
      </div>
    </div>
  )
}

function StatusBadge({ status }: { status: string }) {
  const statusColors = {
    Active: "bg-green-100 text-green-800",
    Maintenance: "bg-yellow-100 text-yellow-800",
    "In Development": "bg-gray-100 text-gray-800",
  }
  const colorClass = statusColors[status as keyof typeof statusColors] || statusColors["In Development"]

  return (
    <span className={`px-2 py-1 text-xs font-semibold rounded-full ${colorClass}`}>
      {status}
    </span>
  )
}

function LaunchButton({ url }: { url: string }) {
  const token = localStorage.getItem('access-token');
  url = token? `${url}?token=${token}` : url;
  return (
    <Button
      className="w-full bg-[#1b365d] hover:bg-[#00407A]"
      onClick={() => window.open(url, "_top")}
    >
      <ExternalLink className="mr-2 h-4 w-4" />
      Launch
    </Button>
  )
}

export default Home;